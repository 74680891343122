'use client';
import { Button, Flex, Stack, Text, Title } from '@mantine/core';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { LazyLottie } from '~/Components/Shared/Lottie';

export default function NotFound() {
  const { t } = useTranslation('errorPage');

  return (
    <Stack>
      <Flex justify={'center'} pos="relative" mah={'400px'} mt="xl">
        <LazyLottie
          getJson={() => import('../../assets/lottie/404.json')}
          loop
          id="error-404"
          width={400}
          height={400}
        />
      </Flex>
      <Stack align="center" justify="center" mb="md" gap={0}>
        <Title order={1}>{t('Oops!')}</Title>
        <Text size={'xl'} fw="500">
          {t('Page not found')}
        </Text>
      </Stack>
      <Stack align="center" justify="center">
        <Link href="/cards">
          <Button size="lg" variant="light">
            {t('Go Back To Cards')}
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
}
